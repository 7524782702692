.notification-container {
  width: 400px;
  height: 400px;
  background-color: white;
  z-index: 99;
  border-radius: 20px;
  box-shadow: 0 8px 77px rgba(0, 0, 0, 0.4);
}

.up-container {
  padding: 18px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.notification-title {
  font-weight: 700;
  font-size: 18px;
}

.close-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 1px 2px 9px -1px rgba(66, 68, 90, 1);
}

.middle-line-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1px;
}

.middle-line {
  width: 100%;
  height: 1px;
  background-color: black;
}

.down-container {
  width: 100%;
  height: 305px;
  overflow-y: auto;
  /* background-color: rgb(245, 245, 245); */
}

.notification {
  width: 100%;
  height: 97px;
  cursor: pointer;
  border-bottom: 1px solid rgba(128, 128, 128, 0.507);
}

@media (max-width: 500px) {
  .notification-container {
    width: 95%;
    right: 0;
    left: 4px;
    top: 0;
  }
}

@media (max-width: 375px) {
  .notification-container {
    width: 95%;
    left: 6px;
  }
}

@media (max-width: 349px) {
  .notification-container {
    width: 95%;
    left: 8px;
  }
}

.custom-file-viewer-modal {
  position: relative;
  width: 75%;
  max-height: 90vh;
  overflow: auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 640px) {
  .custom-file-viewer-modal {
    width: 90%;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .custom-file-viewer-modal {
    width: 80%;
  }
}

@media (min-width: 1008px) {
  .custom-file-viewer-modal {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .custom-file-viewer-modal {
    zoom: 50%;
  }
}

/* Styles for the container*/
.chat-container {
  /* position: absolute;
  width: calc(100% - 380px); */
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 30px;
  border-radius: 9px;
  background-color: #5b539d1f;
  /* right: 10px;
  left: 362px; */
}

.chat-container__isChatPageMod {
  position: absolute;
  width: calc(100% - 380px);
  display: flex;
  align-items: center;
  margin-top: 5px;
  height: 30px;
  border-radius: 9px;
  background-color: #5b539d1f;
  right: 10px;
  left: 362px;
}

.chat-container-mobile {
  position: absolute;
  bottom: 10px;
  left: 2%;
  width: 96%;
}

.picker-container {
  position: absolute;
  bottom: 75px;
  width: 300px;
  right: 58.3px;
}

.message-input {
  flex: 1;
  margin-right: 10px;
  font-size: 13px;
  background-color: #5a539d00;
}

/* .emoji-button {

}

.send-button {

} */

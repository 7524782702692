/* PhotoModal.css */

/* Styles for a black background with a translucency that covers all content */
.photo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 31%); /* Translucent black color */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for a modal window */
.photo-modal {
  width: 800px;
  height: 600px;
  display: flex;
  justify-content: center;
  padding: 20px;
  position: relative;
}

/* Styles for the close button */
.close-button {
  position: absolute;
  top: 17px;
  right: 25px;
  background-color: white;
  border: none;
  color: black; /* Color of the button text */
  cursor: pointer;
  font-weight: 700;
}

/* Styles for the image in the modal window */
.modal-image {
  max-width: 100%; /* The image will not be larger than its container */
  max-height: 100vh; /* The image will not be higher than its container */
}

/* Responsive styles for the modal window on smaller screens (mobile devices) */
@media (max-width: 768px) {
  .photo-modal {
    width: 80%;
    height: auto;
    max-height: 80vh;
  }
}

@media (max-width: 600px) {
  .photo-modal {
    width: 70%;
    height: auto;
    max-height: 60vh;
  }
}

@media (max-width: 530px) {
  .photo-modal {
    width: 70%;
    height: auto;
    max-height: 50vh;
  }
}

@media (max-width: 430px) {
  .photo-modal {
    width: 80%;
    height: auto;
    max-height: 50vh;
  }
}

@media (max-width: 380px) {
  .photo-modal {
    width: 90%;
    height: auto;
    max-height: 50vh;
  }
}

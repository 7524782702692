.str-chat-channel .str-chat__container {
  height: 100%;
}

.str-chat-channel {
  /* max-height: 0; */
}

.str-chat {
  height: 105%;
}

.str-chat__message-reaction-emoji {
  margin-top: 5px;
}

.str-chat__message-reaction-emoji {
  margin-top: 5px;
}

/* Стилі для блока, що містить dropdown */
.dropdown-container {
  position: relative;
  background-color: var(--grey-gainsboro);
  height: 40px;
  width: 100%;
}

/* Стилі для блока зі списком користувачів */
.dropdown-list {
  width: 300px;
  flex-direction: column;
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

/* Стилі для окремих елементів списку */
.dropdown-item {
  padding: 5px;
  cursor: pointer;
  display: flex;
}

/* Стилі для кнопки "Send a message" */
.send-message-btn {
  font-size: 10px;
  color: blue;
  margin-left: 10px;
}

.dropdown-item:hover {
  background-color: rgba(128, 128, 128, 0.422);
}

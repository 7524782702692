/* styles.css */
.searchBarDrop__main {
  width: 100%;
  height: 300px;
  position: absolute;
  z-index: 99;
  top: 75px;
  left: 0;
  right: 0;
  padding: 0;
  display: flex;
  /* justify-content: center; */
}

.searchBarDropDownContainer {
  width: 100%;
  height: 300px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 77px;
  overflow-y: auto;
}

.startupLink {
  width: 100%;
  display: flex;
  cursor: pointer;
}

.startupLink:hover {
  background-color: #80808042;
}

.startupLogoContainer {
  width: 12%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startupLogo {
  width: 40px;
  height: 40px;
}

.startupDetailsContainer {
  width: 80%;
  height: 60px;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .searchBarDrop__main {
    top: 125px;
  }
}

@media (max-width: 501px) {
  .searchBarDrop__main {
    top: 107px;
  }
}

.title__container {
  max-width: 600px;
}

@media (max-width: 500px) {
  .frame__mod {
    width: 92% !important;
    left: 4% !important;
  }
  .imageLogo__mode {
    border-radius: 0 !important;
  }
}

@media (max-width: 500px) {
  .product_image_mod {
    max-width: 220px !important;
  }
  .title__container {
    width: 60%;
  }
  .title__container__mod {
    width: 90%;
    margin-right: 10px;
  }
}

@media (max-width: 390px) {
  .frame__mod {
    border-radius: 0 !important;
    width: 91% !important;
    left: 4.5% !important;
  }
  .imageLogo__mode {
    border-radius: 0 !important;
  }
  .imageLogo__mode-2 {
    border-radius: 0 !important;
  }
}

@media (max-width: 340px) {
  .title__container {
    width: 40%;
  }
  .title__container__mod {
    width: 100%;
    margin-right: 12px;
  }
}

@media (max-width: 600px) {
  .data-room-mod {
    zoom: 90%;
  }
}

@media (max-width: 541px) {
  .data-room-mod {
    zoom: 80%;
  }
}

@media (max-width: 487px) {
  .data-room-mod {
    zoom: 70%;
  }
}

@media (max-width: 431px) {
  .data-room-mod {
    zoom: 60%;
  }
}

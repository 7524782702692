/* ChannelList.css */

.channel-list {
  list-style: none;
  padding-left: 3px;
}

.channel-item {
  width: 100%;
  display: flex;
  height: 40px;
  cursor: pointer;
  border-top: 1px solid #95959570;
  background-color: white;
}

.channel-item.unread {
  background-color: rgba(128, 128, 128, 0.17); /* Style for unread channels */
}

.channel-avatar {
  width: 50px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.channel-details {
  font-size: 12px;
  margin-top: 11px;
  margin-left: 5px;
}

.channel-name {
  height: 15px;
}

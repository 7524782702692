/* MessageList.css */

.message-list-container {
  height: 380px;
  overflow-y: scroll;
  height: calc(100% - 60px);
  overflow-y: scroll;
}

.message-list-container__MCP {
  /* height: 91%; */
  overflow-y: scroll;
  /* 
  search-container: 40px + 
  channel-header-container: 30px + 
  header 105px * 0.9 (90% zoom) + 
  bottom chat input 50px 
  = 214.5px */
  height: calc(100dvh - 173.5px);
}

.message {
  display: flex;
  justify-content: flex-end;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 8px;
}

@media (max-width: 990px) {
  .message-list-container__MCP {
    height: calc(100dvh - 211.5px);
  }
}

@media (max-width: 800px) {
  .message-list-container__MCP {
    height: calc(100dvh - 232.5px);
  }
}

@media (max-width: 500px) {
  .message-list-container__MCP {
    height: calc(100dvh - 217.5px);
  }
}

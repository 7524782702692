.dot {
  font-size: 3.2em;
}

/* Disable stroke for all inputs after focus */
input:focus {
  outline: none; /* Remove the stroke */
}
.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
}

.pdf-canvas {
  display: flex !important;
  justify-content: center !important;
}

.file-upload-label {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  display: inline-block;
  width: 0;
  height: 0;
}

::placeholder {
  color: black;
}

input::placeholder {
  color: black;
}

textarea::placeholder {
  color: black;
}

.zoom-out {
  zoom: 90%;
}

.raising {
  width: 100%;
  appearance: none;
  height: 2px;
  background: grey;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  cursor: pointer;
}

.raising::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
  transform: translateX(10px); /* Змінено значення трансформації */
}

.raising::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
  transform: translateX(10px); /* Змінено значення трансформації */
}

.value {
  margin-top: 10px;
  font-weight: bold;
}

.pathStyle {
  width: 23%;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
}

.pathStyle:hover {
  background-color: #3f8efc26;
}

.filesStyle {
  background-color: none;
}

.filesStyle:hover {
  background-color: #3f8efc26;
}

.main__bg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

/* LOADER */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255 255 255 / 39%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #3f8efc;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.textAreaDescription {
  resize: none;
  max-width: 630px;
  width: 100%;
  height: 150px;
  line-height: 26px;
  font-size: 17px;
  margin-top: 10px;
  text-align: left;
  word-wrap: break-word;
}

.col {
}

.featureEdit {
}

.featureEdit2 {
}

.bottom__descEdit {
}

.tagEdit {
}

.setting__button {
  height: 40px;
  margin: 0 !important;
  width: 100%;

  display: flex;
  align-items: center;
}

.setting__button:hover {
  background-color: rgb(166, 198, 241);
}

.startup__link {
}
.featureEdit3 {
}
.featureEdit4 {
}

.r2fw2t42 {
  width: 37.3%;
}

.e43g2ew {
  right: -61px;
  max-width: 17px;
}

.e43g2ew2 {
  right: -64px;
  max-width: 17px;
}

.navbar__icons {
  width: 25px;
  height: 25px;
}

.burger-icon {
  width: 20px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.burger-icon.active {
  transform: rotate(0deg);
}

.mod__1 {
  max-width: 100%;
}

.bar {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: transform 0.3s ease-in-out;
}

.bar1-active {
  width: 19px;
  margin-top: 1px;
  transform: translateY(6px) rotate(45deg);
}

.bar2-active {
  opacity: 0;
}

.bar3-active {
  width: 19px;
  transform: translateY(-6px) rotate(-45deg);
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.modal-overlay {
  position: absolute;
  top: 42px;
  left: -17px;
  right: -9px;
  width: 111%;
  height: 80px;
  border-bottom: 1px solid black;
  z-index: 999;
  background-color: white;
}

@media (max-width: 1440px) {
  .r2fw2t42 {
    width: 39.4%;
  }
}
@media (max-width: 1200px) {
  .startup__link {
    font-size: 14px !important;
  }
  .featureEdit {
    width: 100% !important;
  }
  .featureEdit2 {
    width: 80% !important;
  }
  .featureEdit3 {
    width: 66.8% !important;
  }
  .featureEdit4 {
    width: 68.9% !important;
  }
  .featureEdit5 {
    width: 80.8% !important;
  }
  .r2fw2t42 {
    width: 47.9%;
    height: 160px !important;
  }
}

@media (max-width: 991px) {
  .tagEdit {
    width: 100%;
  }
  .bottom__descEdit {
    width: 100%;
  }
  .featureEdit {
    width: 100% !important;
  }
  .featureEdit2 {
    width: 83% !important;
  }
  .featureEdit3 {
    width: 70.4% !important;
  }
  .featureEdit4 {
    width: 68.9% !important;
  }
  .r2fw2t42 {
    width: 63.8%;
    height: 160px !important;
  }
}

@media (max-width: 984px) {
  .col {
    justify-content: center;
  }
  .e43g2ew {
    right: -41px;
    max-width: 17px;
  }
  .e43g2ew2 {
    right: -44px;
    max-width: 17px;
  }
}

@media (max-width: 562px) {
  .login__screen-button {
    font-size: 16px;
  }
  .textAreaDescription {
    margin-bottom: 12px;
    font-size: 15px;
    letter-spacing: -1px;
  }
  .startup__link {
    font-size: 12px !important;
  }
  .featureEdit3 {
    width: 61.8% !important;
  }
  .featureEdit4 {
    width: 61.8% !important;
  }
  .r2fw2t42 {
    width: 93.8%;
    height: 170px !important;
  }
}

@media (max-width: 407px) {
  .mod__1 {
    max-width: 90%;
  }
  .login__screen-button {
    font-size: 14px;
  }
  .textAreaDescription {
    margin-bottom: 9px;
    letter-spacing: -1px;
    font-size: 15px;
  }
  .featureEdit3 {
    width: 56.2% !important;
  }
  .featureEdit4 {
    width: 56.2% !important;
  }
  .r2fw2t42 {
    width: 93%;
    height: 190px !important;
  }
}

@media (max-width: 400px) {
  .mod__1 {
    max-width: 69%;
  }
  .header__buttons {
    display: none;
  }

  .burger__menu {
    display: flex;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PrivacyPolicy__container {
  width: 70%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 20px;
}

@media (max-width: 500px) {
  .PrivacyPolicy__container {
    width: 92%;
  }
}

body,
html {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "AvenirNext", sans-serif;
  color: #010000;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Book.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Heavy.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Black.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNextCyr-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNextCyr-Demi.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNextCyr-Heavy.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNextCyr-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNextCyr-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNextCyr-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

header {
  background-color: white;
  border-bottom: 1px solid #979797;
}
header .outer__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 0px;
  position: relative;
}
header .outer__header .search__wrapper {
  position: absolute;
  left: 240px;
  width: 100%;
  max-width: 400px;
}
header .outer__header .search__wrapper a {
  display: none;
}
header .outer__header .search__wrapper form {
  width: 100%;
}
header .outer__header .search__wrapper form > input {
  outline: none;
  color: #010000;
  font-size: 16px;
  width: 100%;
  height: 45px;
  border: 1px solid #979797;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 250px;
}
header .outer__header .header__left > a {
  font-family: "Futura", sans-serif;
  font-size: 28px;
  text-decoration: none;
  font-weight: 500;
  color: #010000;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
header .outer__header .header__left > a:hover {
  opacity: 0.7;
}
header .outer__header .header__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
header .outer__header .header__right .profile__wrapper {
  position: relative;
}
header .outer__header .header__right .profile__wrapper .profile__box {
  position: absolute;
  right: 0px;
  min-width: 300px;
  top: 60px;
  background-color: #fff;
  border-radius: 20px;
  z-index: 4;
  max-width: 360px;
  -webkit-box-shadow: 0 5px 77px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 77px rgba(0, 0, 0, 0.23);
  padding: 15px;
}
header .outer__header .header__right .profile__wrapper .profile__box > ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  > ul
  > li.spacer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0px 5px;
  font-size: 13px;
  padding-top: 3px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  > ul
  > li
  > a {
  font-size: 13px;
  color: #010000;
  text-decoration: none;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  > ul
  > li
  > a:hover {
  opacity: 0.7;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list {
  padding-left: 15px;
  padding-right: 15px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li {
  margin-bottom: 20px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a:hover {
  opacity: 0.7;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a
  .desc__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a
  .desc__
  > p {
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a
  .desc__
  > span {
  color: #010000;
  font-size: 14px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a
  > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;
  border-radius: 250px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  margin-right: 20px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li
  > a
  > span
  img {
  max-height: 30px;
  max-width: 20px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__list
  ul
  > li:last-child {
  margin-bottom: 0px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #262626;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__info
  .profile__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__info
  .profile__desc
  > p {
  margin: 0px;
  font-size: 22px;
  color: #010000;
  font-weight: 600;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__info
  .profile__desc
  > span {
  font-size: 14px;
  line-height: 16px;
  color: #010000;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__info
  > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
}
header
  .outer__header
  .header__right
  .profile__wrapper
  .profile__box
  .profile__info
  > span
  > img {
  min-width: 65px;
  max-width: 65px;
}
header .outer__header .header__right .profile__wrapper > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
  font-weight: 600;
  color: #010000;
  font-size: 22px;
}
header .outer__header .header__right .profile__wrapper > a span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 12px;
}
header .outer__header .header__right .profile__wrapper > a span > img {
  min-width: 50px;
  max-width: 50px;
  border-radius: 250px;
  -o-object-fit: cover;
  object-fit: cover;
}
header .outer__header .header__right .header__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: center;
  margin-right: 25px;
}
header
  .outer__header
  .header__right
  .header__buttons
  .notifications__button
  > a {
  position: relative;
}
header
  .outer__header
  .header__right
  .header__buttons
  .notifications__button
  > a
  > img {
  max-width: 22px;
}
header
  .outer__header
  .header__right
  .header__buttons
  .notifications__button
  > a
  > span {
  position: absolute;
  top: -3px;
  right: -6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 3px;
  background-color: #fff;
  border-radius: 250px;
}
header
  .outer__header
  .header__right
  .header__buttons
  .notifications__button
  > a
  > span
  > span {
  min-width: 13px;
  max-width: 13px;
  min-height: 13px;
  max-height: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 250px;
  font-size: 9px;
  font-weight: bold;
  color: #000;
}
header .outer__header .header__right .header__buttons > div {
  margin-right: 25px;
}
header .outer__header .header__right .header__buttons > div:last-child {
  margin-right: 0px;
}
header .outer__header .header__right .header__buttons a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
header .outer__header .header__right .header__buttons a > img {
  max-width: 25px;
}
header .outer__header .header__right .sign__Ini {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
header .outer__header .header__right .sign__Ini .profile__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
header .outer__header .header__right .sign__Ini .profile__icon > img {
  max-width: 45px;
}
header .outer__header .header__right .sign__Ini > a {
  font-size: 21px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 400;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
  margin-right: 0px;
}
header .outer__header .header__right .sign__Ini > a .profile__icon {
  margin-left: 20px;
}

.login__wrapper {
  height: calc(100vh - 76px);
  width: 100%;
  /* background: url("../img/loginback.webp"); */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding-left: 15px;
  padding-right: 15px;
  background-position: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.login__wrapper .sign__box {
  width: 100%;
  max-width: 480px;
  background-color: #fff;
  border-radius: 25px;
  -webkit-box-shadow: 0 6px 25px rgba(0, 0, 0, 0.23);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.23);
  padding: 25px 30px;
}
.login__wrapper .sign__box .sign__main > ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}
.login__wrapper .sign__box .sign__main > ul > li.spacer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0px 7px;
}
.login__wrapper .sign__box .sign__main > ul > li > a {
  font-size: 16px;
  color: #010000;
  text-decoration: none;
}
.login__wrapper .sign__box .sign__main .sign__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.login__wrapper .sign__box .sign__main .sign__buttons > a {
  width: 100%;
  height: 50px;
  background-color: #000000;
  border-radius: 8px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  margin-bottom: 20px;
}
.login__wrapper .sign__box .sign__main .sign__buttons > a:last-child {
  margin-bottom: 0px;
}
.login__wrapper .sign__box .sign__main .sign__buttons > a > img {
  position: absolute;
  right: 25px;
  max-width: 17px;
}
.login__wrapper .sign__box .sign__main > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}
.login__wrapper .sign__box .sign__main > span > img {
  max-width: 90px;
}
.login__wrapper .sign__box .sign__head {
  padding-bottom: 15px;
  border-bottom: 1px solid #262626;
  margin-bottom: 40px;
}
.login__wrapper .sign__box .sign__head > p {
  font-size: 20px;
  margin: 0px;
}

.modal__wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 4;
}
.modal__wrapper > .inner__modal {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal__wrapper > .inner__modal > .modal__box {
  max-height: calc(100% - 30px);
  overflow-y: auto;
}
.modal__wrapper > .inner__modal > .metrics {
  max-width: 940px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;
}
.modal__wrapper > .inner__modal > .metrics .metrics__wrapper {
  display: grid;
  padding-bottom: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.modal__wrapper > .inner__modal > .metrics .metrics__wrapper .elem__metric {
  position: relative;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric:last-child:before {
  content: none;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric:nth-child(1)
  .row__grid {
  padding-left: 0px;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric
  .row__grid {
  padding-left: 30px;
  padding-right: 30px;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric
  .row__grid
  .row__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric
  .row__grid
  .row__:last-child {
  margin-bottom: 0px;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric
  .row__grid
  .row__
  > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  line-height: 24px;
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 400;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric
  .row__grid
  .row__
  > p {
  margin: 0px;
  font-size: 21px;
  font-weight: 500;
  color: #4a4a4a;
  text-transform: uppercase;
}
.modal__wrapper
  > .inner__modal
  > .metrics
  .metrics__wrapper
  .elem__metric:before {
  content: "";
  position: absolute;
  right: 0px;
  top: 40px;
  height: calc(100% - 38px);
  width: 1px;
  background: url("../img/dash.svg");
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}
.modal__wrapper > .inner__modal > .metrics .metrics__wrapper .elem__metric > p {
  margin: 0px;
  min-height: 24px;
  margin-bottom: 15px;
  font-size: 20px;
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 400;
}
.modal__wrapper > .inner__modal > .metrics > .head__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #979797;
  margin-bottom: 15px;
}
.modal__wrapper > .inner__modal > .metrics > .head__ > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  border-radius: 250px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal__wrapper > .inner__modal > .metrics > .head__ > a img {
  max-width: 15px;
}
.modal__wrapper > .inner__modal > .metrics > .head__ > p {
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 20px;
  color: #000;
}
.modal__wrapper > .inner__modal > .metrics > .head__ > p span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 8px;
}
.modal__wrapper > .inner__modal > .metrics > .head__ > p span > img {
  max-width: 25px;
}
.modal__wrapper > .inner__modal > .data__room {
  max-width: 580px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;
}
.modal__wrapper > .inner__modal > .data__room > .head__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #979797;
  margin-bottom: 15px;
}
.modal__wrapper > .inner__modal > .data__room > .head__ > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  border-radius: 250px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal__wrapper > .inner__modal > .data__room > .head__ > a img {
  max-width: 15px;
}
.modal__wrapper > .inner__modal > .data__room > .head__ > p {
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 20px;
  color: #000;
}
.modal__wrapper > .inner__modal > .data__room > .head__ > p span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 8px;
}
.modal__wrapper > .inner__modal > .data__room > .head__ > p span > img {
  max-width: 25px;
}
.modal__wrapper > .inner__modal > .data__room .folder__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 40px;
  padding-right: 40px;
  grid-column-gap: 25px;
  grid-row-gap: 40px;
  padding-bottom: 50px;
}
.modal__wrapper > .inner__modal > .data__room .folder__wrapper > .add__folder {
  text-decoration: none;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .add__folder
  > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 250px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.231589);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.231589);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: auto;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .add__folder
  > span
  img {
  max-width: 60px;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .add__folder
  > p {
  margin: 0px;
  margin-top: auto;
  font-size: 18px;
  color: #010000;
}
.modal__wrapper > .inner__modal > .data__room .folder__wrapper > .elem__folder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .elem__folder:hover {
  opacity: 0.7;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .elem__folder
  > p {
  margin: 0px;
  font-size: 18px;
  color: #010000;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .elem__folder
  > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.modal__wrapper
  > .inner__modal
  > .data__room
  .folder__wrapper
  > .elem__folder
  > span
  > img {
  max-width: 100%;
}
.modal__wrapper > .inner__modal > .contact__modal,
.modal__wrapper > .inner__modal > .share__modal {
  max-width: 440px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;
}
.modal__wrapper > .inner__modal > .contact__modal .company__info,
.modal__wrapper > .inner__modal > .share__modal .company__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
}
.modal__wrapper > .inner__modal > .contact__modal .company__info > p,
.modal__wrapper > .inner__modal > .share__modal .company__info > p {
  margin-top: 12px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  color: #656363;
  font-weight: 400;
}
.modal__wrapper > .inner__modal > .contact__modal .company__info .desc__ > span,
.modal__wrapper > .inner__modal > .share__modal .company__info .desc__ > span {
  color: #010000;
  font-size: 13px;
}
.modal__wrapper > .inner__modal > .contact__modal .company__info .desc__ > p,
.modal__wrapper > .inner__modal > .share__modal .company__info .desc__ > p {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  color: #010000;
}
.modal__wrapper > .inner__modal > .contact__modal .company__info > span,
.modal__wrapper > .inner__modal > .share__modal .company__info > span {
  -webkit-box-shadow: 0 8px 24px rgba(0, 0, 0, 0.23);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  padding-top: 5px;
  max-height: 60px;
  margin-bottom: 15px;
  border-radius: 250px;
}
.modal__wrapper > .inner__modal > .contact__modal .company__info > span img,
.modal__wrapper > .inner__modal > .share__modal .company__info > span img {
  max-width: 50px;
  max-height: 34px;
}
.modal__wrapper > .inner__modal > .contact__modal > .head__,
.modal__wrapper > .inner__modal > .share__modal > .head__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #979797;
  margin-bottom: 15px;
}
.modal__wrapper > .inner__modal > .contact__modal > .head__ > a,
.modal__wrapper > .inner__modal > .share__modal > .head__ > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  border-radius: 250px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal__wrapper > .inner__modal > .contact__modal > .head__ > a img,
.modal__wrapper > .inner__modal > .share__modal > .head__ > a img {
  max-width: 15px;
}
.modal__wrapper > .inner__modal > .contact__modal > .head__ > p,
.modal__wrapper > .inner__modal > .share__modal > .head__ > p {
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 20px;
  color: #000;
}
.modal__wrapper > .inner__modal > .contact__modal > .head__ > p span,
.modal__wrapper > .inner__modal > .share__modal > .head__ > p span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 8px;
}
.modal__wrapper > .inner__modal > .contact__modal > .head__ > p span > img,
.modal__wrapper > .inner__modal > .share__modal > .head__ > p span > img {
  max-width: 25px;
}

.group__submit > button {
  width: 100%;
  height: 45px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #040404;
  border: 0px;
  outline: none;
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
.group__submit > button:hover {
  opacity: 0.7;
}

.group__input {
  margin-bottom: 10px;
  width: 100%;
}
.group__input > textarea {
  width: 100%;
  height: 190px;
  resize: none;
  border: 1px solid #979797;
  border-radius: 5px;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  color: #000;
  font-size: 16px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.group__input > input {
  height: 40px;
  border: 1px solid #979797;
  border-radius: 5px;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  color: #000;
  font-size: 16px;
  width: 100%;
}

.companies__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.companies__wrapper > .companies__side {
  min-width: 390px;
  max-width: 390px;
  border-right: 1px solid #979797;
  padding: 25px;
}
.companies__wrapper > .companies__main {
  width: 100%;
  padding: 50px 40px;
}
.companies__wrapper > .companies__main .companies__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 60px;
}
.companies__wrapper > .companies__main .companies__grid > .elem__company {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  .company__desc
  > .desc__
  > p {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 25px;
  color: #010000;
  font-weight: 500;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  .company__desc
  > .desc__
  > p:last-child {
  margin-bottom: 0px;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  .company__desc
  > .top__part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  .company__desc
  > .top__part
  > a {
  font-size: 30px;
  font-weight: 600;
  color: #010000;
  text-decoration: none;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  .company__desc
  > .top__part
  > span {
  font-size: 16px;
  line-height: 24px;
  color: #010000;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  > .company__logo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px 15px;
  background-color: #fff;
  margin-right: 45px;
  min-width: 140px;
  min-height: 140px;
}
.companies__wrapper
  > .companies__main
  .companies__grid
  > .elem__company
  > .company__logo
  img {
  max-height: 100%;
  max-width: 100%;
}

/* Customize the label (the container) */
.container__check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 16px;
  color: #010000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container__check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  top: 0;
  left: 0;
  min-height: 25px;
  max-height: 25px;
  max-height: 25px;
  min-width: 25px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  border: 1px solid #979797;
  margin-right: 15px;
  background-color: transparent;
}

.container__check input:checked ~ .checkmark {
  background-color: #306cbe;
  border-color: #306cbe;
  display: flex;
  align-items: center;
  justify-content: center;
}

.companies__side .main__checkboxes {
  margin-bottom: 25px;
}
.companies__side .main__checkboxes:last-child {
  margin-bottom: 0px;
}
.companies__side .wrapper__filter {
  margin-bottom: 25px;
}
.companies__side .wrapper__filter .double__filter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 15px;
  grid-row-gap: 12px;
}
.companies__side .wrapper__filter .double__filter > .elem__checkbox {
  margin-bottom: 0px;
}
.companies__side .wrapper__filter .double__filter > .elem__checkbox.double {
  grid-column-start: 1;
  grid-column-end: 3;
}
.companies__side .wrapper__filter:last-child {
  margin-bottom: 0px;
}
.companies__side .wrapper__filter > p {
  margin: 0px;
  border-bottom: 1px solid #979797;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.elem__checkbox {
  margin-bottom: 12px;
}
.elem__checkbox:last-child {
  margin-bottom: 0px;
}

.irs--flat .irs-bar--single {
  display: none !important;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  content: none !important;
}

span.irs--flat .irs-handle > i:first-child {
  width: 18px;
  border-radius: 250px;
  height: 18px;
  background-color: #363636 !important;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
  display: none !important;
}

.irs--flat .irs-single {
  color: #000 !important;
  background-color: transparent !important;
  font-size: 13px !important;
  font-weight: 500;
  margin-left: 9px;
}

.irs--flat .irs-line {
  top: 31px !important;
  height: 2px !important;
  background-color: #979797 !important;
}

.team__wrapper {
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid #979797;
}
.team__wrapper > p {
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
}
.team__wrapper .team__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}
.team__wrapper .team__grid > .elem__team {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.team__wrapper .team__grid > .elem__team .team__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.team__wrapper .team__grid > .elem__team .team__desc > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 8px;
}
.team__wrapper .team__grid > .elem__team .team__desc > a > img {
  max-width: 24px;
}
.team__wrapper .team__grid > .elem__team .team__desc > span {
  font-size: 18px;
  line-height: 24px;
}
.team__wrapper .team__grid > .elem__team .team__desc > p {
  margin-top: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin: 0px;
  margin-bottom: 5px;
}
.team__wrapper .team__grid > .elem__team > .team__image {
  margin-right: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.team__wrapper .team__grid > .elem__team > .team__image > img {
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  border-radius: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 150px;
}

.info__grid {
  border-bottom: 1px solid #979797;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 35px;
  grid-column-gap: 30px;
}
.info__grid .elem__info {
  padding-right: 35px;
  position: relative;
}
.info__grid .elem__info:last-child:before {
  content: none;
}
.info__grid .elem__info:before {
  content: "";
  width: 1px;
  height: calc(100% - 110px);
  position: absolute;
  right: 0px;
  top: 110px;
  background: url("../img/dash.svg");
  background-repeat: repeat-y;
  background-size: 3px;
  background-position: center;
}
.info__grid .elem__info .content__ > .row {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.info__grid .elem__info .content__ > .row:last-child {
  margin-bottom: 0px;
}
.info__grid .elem__info .content__ > .row > p {
  margin: 0px;
  font-size: 38px;
  font-weight: 400;
  color: #4a4a4a;
}
.info__grid .elem__info .content__ > .row > span {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 7px;
  display: block;
  color: #4a4a4a;
  text-transform: uppercase;
}
.info__grid .elem__info > .head__ > p {
  margin: 0px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
  color: #4a4a4a;
  min-height: 110px;
}

.elem__grid__mod {
  align-items: center !important;
  padding-right: 0 !important;
}

.main__grid {
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid #979797;
}
.main__grid .grid__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: calc(100% + 50px);
  margin-left: -25px;
}
.main__grid .grid__wrapper > .elem__grid {
  position: relative;
  padding: 30px 25px;
}
.main__grid .grid__wrapper > .elem__grid:last-child {
  padding-right: 50px;
}
.main__grid .grid__wrapper > .elem__grid:last-child:before {
  content: none;
}
.main__grid .grid__wrapper > .elem__grid > p {
  margin: 0px;
  font-size: 18px;
  line-height: 26px;
  color: #4a4a4a;
}
.main__grid .grid__wrapper > .elem__grid > h6 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  color: #4a4a4a;
}
.main__grid .grid__wrapper > .elem__grid:before {
  content: "";
  position: absolute;
  right: -1px;
  top: 0px;
  width: 1px;
  height: 100%;
  background: url("../img/dash.svg");
  background-repeat: repeat-y;
  background-size: 3px;
  background-position: center;
}
.main__grid > p {
  margin-bottom: 20px;
  margin-top: 0px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
}

footer .outer__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
footer .outer__footer ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
footer .outer__footer ul > li {
  margin-right: 18px;
}
footer .outer__footer ul > li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  font-weight: 500;
  font-size: 17px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
}
footer .outer__footer ul > li > a span {
  margin-right: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
footer .outer__footer ul > li > a span > img {
  max-width: 28px;
}
footer .outer__footer ul > li:last-child {
  margin-right: 0px;
}
footer .outer__footer .footer__desc {
  margin-right: 20px;
}
footer .outer__footer .footer__desc > p {
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
  font-weight: 500;
}

.group__checkbox__modal {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px;
}

.main__top {
  margin-bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 45px;
}
.main__top .top__desc {
  max-width: 640px;
}
.main__top .top__desc > p {
  font-size: 18px;
  line-height: 26px;
  color: #010000;
}
.main__top .top__desc > .list .bottom__desc > p {
  margin: 0px;
  font-size: 18px;
  line-height: 26px;
  color: #010000;
}
.main__top .top__desc > .list > p {
  margin: 0px;
  font-size: 18px;
  line-height: 26px;
  color: #010000;
  margin-bottom: 10px;
}
.main__top .top__desc > .list > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 12px;
}
.main__top .top__desc > .list > ul > li {
  margin: 0px;
  font-size: 18px;
  color: #010000;
  line-height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.main__top .top__desc > .list > ul > li > p {
  margin: 0px;
}
.main__top .top__desc > .list > ul > li > span {
  font-size: 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 7px;
}
.main__top .top__desc .head__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.main__top .top__desc .head__ > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 90px;
  min-height: 90px;
  max-width: 90px;
  max-height: 90px;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
}
.main__top .top__desc .head__ > span > img {
  max-width: 100%;
  max-height: 100%;
}
.main__top .top__desc .head__ > .info__ {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.main__top .top__desc .head__ > .info__ > h2 {
  margin-top: 0px;
  font-size: 50px;
  font-weight: 600;
  color: #010000;
  line-height: 58px;
  margin-bottom: 0px;
  margin-bottom: 5px;
}
.main__top .top__desc .head__ > .info__ > p {
  font-size: 24px;
  color: #010000;
  font-weight: 600;
  margin: 0px;
}
.main__top .top__desc ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -5px;
}
.main__top .top__desc ul > li {
  margin: 5px 9px;
}
.main__top .top__desc ul > li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  font-weight: 500;
  font-size: 17px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-decoration: none;
}
.main__top .top__desc ul > li > a span {
  margin-right: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.main__top .top__desc ul > li > a span > img {
  max-width: 28px;
}
.main__top .top__desc ul > li:last-child {
  margin-right: 0px;
}
.main__top .top__media {
  max-width: 740px;
  margin-left: 40px;
  width: 100%;
  min-width: 680px;
}
.main__top .top__media .product__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -160px;
  max-width: 320px;
}
.main__top .top__media .product__image > a > img {
  max-width: 80px;
}
.main__top .top__media .product__image > img {
  width: 100%;
  max-width: 320px;
}
.main__top .top__media > .mobile__ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -280px;
  position: relative;
  z-index: 2;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  max-width: 240px;
  margin-right: 50px;
}
.main__top .top__media > .mobile__ > .frame {
  position: absolute;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px;
}
.main__top .top__media > .mobile__ > .frame > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 30px;
}
.main__top .top__media > .mobile__:before {
  content: "";
  position: absolute;
  width: calc(100% + 120px);
  height: calc(100% + 120px);
  left: -60px;
  top: -60px;
  background: url("../img/shadow.webp");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.7;
  background-position: center;
}
.main__top .top__media > .mobile__ > img {
  max-width: 240px;
  position: relative;
  z-index: 3;
}
.main__top .top__media > .desktop__ {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}
.main__top .top__media > .desktop__ > .frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 3px;
  top: 0px;
}
.main__top .top__media > .desktop__ > .frame > a > img {
  max-width: 70px;
}
.main__top .top__media > .desktop__ > .frame > img {
  border-radius: 50px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.main__top .top__media > .desktop__ > img {
  position: relative;
  z-index: 2;
  max-width: 100%;
}

.editable {
  outline: none;
  border: 1px dashed #979797;
}

.upload {
  position: relative;
}
.upload > input {
  position: absolute;
  left: -3000px;
}
.upload > a {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  z-index: 3;
  text-decoration: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.upload > a > span {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #010000;
  border-radius: 8px;
  max-width: 150px;
  min-width: 120px;
  -webkit-box-shadow: 7px 0px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 7px 0px 15px rgba(0, 0, 0, 0.15);
  padding: 7px 12px;
  text-align: center;
  background-color: #ffffff;
}
.upload > a > img {
  max-width: 45px;
}

.mobile__.empty:before {
  opacity: 0.3 !important;
}
.mobile__.empty > img {
  opacity: 0.6;
}
.mobile__.empty > .frame > img {
  opacity: 0.6;
}

.desktop__.empty > img {
  opacity: 0.6;
}
.desktop__.empty > .frame > img {
  opacity: 0.6;
}

.main__top .top__media > .desktop__ > .frame > a > img {
  max-width: 80px;
}

.main__top .top__media > .mobile__ > .frame > a > img {
  max-width: 80px;
}

svg {
  width: 35px !important;
  height: 35px !important;
}

@media (max-width: 1640px) {
  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    > .company__logo {
    min-width: 120px;
    min-height: 120px;
    margin-right: 25px;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    .company__desc
    > .desc__
    > p {
    font-size: 16px;
    line-height: 23px;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    .company__desc
    > .top__part
    > a {
    font-size: 24px;
  }
}
@media (max-width: 1480px) {
  .team__wrapper .team__grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .team__wrapper .team__grid > .elem__team > .team__image > img {
    min-width: 135px;
    max-width: 135px;
    min-height: 135px;
    max-height: 135px;
  }

  .team__wrapper .team__grid > .elem__team .team__desc > p {
    font-size: 21px;
    line-height: 27px;
  }

  .companies__wrapper > .companies__side {
    min-width: 360px;
    max-width: 360px;
  }

  .companies__wrapper > .companies__main {
    padding: 35px 20px;
  }

  .companies__wrapper > .companies__main .companies__grid {
    grid-column-gap: 30px;
    grid-row-gap: 50px;
  }
}
.companies__side > a {
  display: none;
}

.upload > a > span {
  margin-top: 0px;
}

@media (max-width: 1280px) {
  .upload > a > span {
    font-size: 10px;
  }

  .main__top .top__media .product__image {
    max-width: 240px;
  }

  .main__top .top__media {
    min-width: 580px;
    max-width: 580px;
  }

  .main__top .top__media > .mobile__ {
    max-width: 200px;
    margin-right: 50px;
  }

  .main__top .top__media > .mobile__ > img {
    max-width: 200px;
  }

  .main__top .top__media > .mobile__:before {
    width: calc(100% + 60px);
    height: calc(100% + 60px);
    left: -30px;
    top: -30px;
  }

  .main__top .top__media > .mobile__ {
    margin-top: -210px;
  }

  .main__top .top__media .product__image > img {
    max-width: 240px;
  }
}
@media (max-width: 1200px) {
  .main__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .main__top .top__media {
    max-width: 640px;
    margin-bottom: 80px;
    margin-left: 0px;
  }

  .main__top .top__desc {
    max-width: 100%;
  }

  .main__top .top__desc .head__ > .info__ > h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .info__grid .elem__info > .head__ > p {
    font-size: 24px;
  }

  .team__wrapper > p {
    font-size: 24px;
  }

  .info__grid .elem__info .content__ > .row > span {
    font-size: 24px;
  }

  .info__grid .elem__info .content__ > .row > p {
    font-size: 32px;
  }

  .team__wrapper .team__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .main__grid .grid__wrapper > .elem__grid > h6 {
    font-size: 24px;
  }

  .main__grid .grid__wrapper > .elem__grid > p {
    font-size: 16px;
    line-height: 24px;
  }

  .main__grid > p {
    font-size: 24px;
  }

  footer .outer__footer ul > li > a {
    font-size: 15px;
  }

  footer .outer__footer ul > li > a span > img {
    max-width: 20px;
  }

  .companies__side .wrapper__filter .double__filter {
    grid-template-columns: 1fr;
  }

  .companies__side .wrapper__filter .double__filter > .elem__checkbox.double {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .companies__wrapper > .companies__side {
    position: fixed;
    left: 0px;
    top: 0px;
    min-width: 290px;
    max-width: 290px;
    left: -290px;
    -webkit-transition: 0.6s ease all;
    transition: 0.6s ease all;
    top: 0px;
    background-color: #fff;
    z-index: 4;
    height: 100%;
  }
  .companies__wrapper > .companies__side > a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-box-shadow: 7px 0px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 7px 0px 5px rgba(0, 0, 0, 0.05);
    position: absolute;
    right: -36px;
    bottom: 0px;
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;
  }
  .companies__wrapper > .companies__side > a.active > span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 8px;
  }
  .companies__wrapper > .companies__side > a.active > span:nth-child(3) {
    bottom: 8px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .companies__wrapper > .companies__side > a.active > span:nth-child(2) {
    opacity: 0;
  }
  .companies__wrapper > .companies__side > a > span {
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    min-width: 24px;
    min-height: 3px;
    border-radius: 3px;
    background-color: #000;
    margin-bottom: 5px;
    position: relative;
    top: 0px;
    bottom: auto;
  }
  .companies__wrapper > .companies__side > a > span:last-child {
    top: auto;
    bottom: 0px;
    margin-bottom: 0px;
  }
  .companies__wrapper > .companies__side form {
    max-height: 100%;
    overflow-y: auto;
  }

  header .outer__header .search__wrapper {
    left: 180px;
  }

  .companies__wrapper > .companies__side {
    padding: 0px;
  }

  .companies__wrapper > .companies__side form {
    padding: 20px 22px;
  }
}
.search__button {
  display: none;
}

.team__image.upload > a > img {
  max-width: 65px;
}

@media (max-width: 991px) {
  .main__top {
    padding-top: 25px;
  }

  .main__top .top__desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main__grid .grid__wrapper > .elem__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main__grid .grid__wrapper > .elem__grid > h6 br {
    display: none;
  }

  .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .list .bottom__desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .info__grid .elem__info .content__ > .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .main__top .top__desc > .list > ul > li {
    font-size: 16px;
    line-height: 24px;
  }

  .main__top .top__desc > .list .bottom__desc > p {
    font-size: 16px;
    line-height: 24px;
  }

  .main__top .top__desc > p {
    font-size: 16px;
    line-height: 24px;
  }

  .info__grid .elem__info {
    text-align: center;
    margin-bottom: 45px;
    width: 100%;
  }
  .info__grid .elem__info:last-child {
    margin-bottom: 0px;
  }

  .info__grid .elem__info:before {
    width: 100%;
    height: 1px;
    left: 0px;
    right: 0px;
    bottom: -23px;
    top: auto;
    background: url("../img/dashvertical.svg");
    background-repeat: repeat-x;
    background-size: cover;
  }

  .info__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-column-gap: 0px;
    grid-row-gap: 0px !important;
  }

  .info__grid {
    grid-template-columns: 1fr;
    grid-row-gap: 35px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .team__wrapper .team__grid {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 35px;
  }

  .team__wrapper .team__grid > .elem__team {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .team__wrapper .team__grid > .elem__team .team__desc {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .team__wrapper .team__grid > .elem__team > .team__image {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .main__grid {
    margin-bottom: 25px;
    padding-bottom: 25px;
  }

  .main__grid .grid__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .main__grid .grid__wrapper > .elem__grid > h6 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .main__grid .grid__wrapper > .elem__grid > p {
    font-size: 14px;
    line-height: 20px;
  }

  .main__grid .grid__wrapper > .elem__grid {
    padding: 20px 15px;
  }

  .main__grid {
    overflow: hidden;
  }

  .main__grid .grid__wrapper {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .main__grid .grid__wrapper > .elem__grid:nth-child(even):nth-child(2):after {
    content: none;
  }
  .main__grid .grid__wrapper > .elem__grid:nth-child(even):after {
    content: "";
    position: absolute;
    right: auto;
    left: 0px;
    top: -1px;
    width: 100%;
    height: 1px;
    background: url("../img/dashvertical.svg");
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center;
  }
  .main__grid .grid__wrapper > .elem__grid:nth-child(odd):nth-child(1):after {
    content: none;
  }
  .main__grid .grid__wrapper > .elem__grid:nth-child(odd):after {
    content: "";
    position: absolute;
    right: auto;
    left: 0px;
    top: -1px;
    width: 100%;
    height: 1px;
    background: url("../img/dashvertical.svg");
    background-repeat: repeat-y;
    background-size: cover;
    background-position: center;
  }

  footer .outer__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  footer .outer__footer .footer__desc {
    margin-right: 0px;
    margin-top: 15px;
  }

  footer .outer__footer ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-top: -5px;
  }
  footer .outer__footer ul > li {
    margin: 5px 9px;
  }

  .companies__wrapper > .companies__main .companies__grid {
    grid-row-gap: 40px;
  }

  .companies__wrapper > .companies__main .companies__grid {
    grid-template-columns: 1fr;
  }

  .companies__wrapper > .companies__main {
    padding: 25px 15px;
    padding-bottom: 40px;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    > .company__logo {
    margin-right: 15px;
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    .company__desc
    > .top__part
    > a {
    font-size: 20px;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    .company__desc
    > .top__part
    > span {
    font-size: 14px;
    line-height: 22px;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    .company__desc
    > .top__part {
    margin-bottom: 10px;
  }

  s header .outer__header .header__right .header__buttons + .search__button {
    margin-left: 0px;
    margin-left: -5px;
    margin-right: 15px;
  }

  header .outer__header .header__right .profile__wrapper > a {
    font-size: 0px;
  }

  header .outer__header .header__right .profile__wrapper > a span {
    margin-left: 0px;
  }

  header .outer__header .header__right .header__buttons {
    margin-right: 20px;
  }

  .login__wrapper .sign__box {
    max-width: 400px;
  }

  .login__wrapper .sign__box .sign__main .sign__buttons {
    max-width: 290px;
  }

  .login__wrapper .sign__box .sign__main .sign__buttons > a {
    height: 45px;
    font-size: 16px;
    line-height: 25px;
  }

  .login__wrapper .sign__box .sign__head > p {
    font-size: 18px;
  }

  .login__wrapper .sign__box .sign__main > ul > li > a {
    font-size: 14px;
  }

  .login__wrapper .sign__box .sign__main > ul > li.spacer {
    font-size: 14px;
  }

  .search__button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: 10px;
  }
  .search__button > a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .search__button > a > img {
    max-width: 20px;
  }

  header .outer__header .header__right .sign__Ini > a {
    font-size: 18px;
  }

  header .outer__header .header__right .sign__Ini > a .profile__icon {
    margin-left: 12px;
  }
  header .outer__header .header__right .sign__Ini > a .profile__icon img {
    max-width: 40px;
    min-width: 40px;
  }

  header .outer__header .header__left > a {
    font-size: 23px;
  }

  header .outer__header .search__wrapper {
    left: 0px;
    width: 100%;
    z-index: 4;
    display: none;
    max-width: 100%;
    height: 100%;
    background-color: #fff;
  }
  header .outer__header .search__wrapper form {
    top: 50%;
    position: relative;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  header .outer__header .search__wrapper form input {
    padding-right: 45px;
    height: 40px;
  }
  header .outer__header .search__wrapper form a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  header .outer__header .search__wrapper form a > img {
    max-width: 20px;
  }

  header .outer__header {
    padding: 10px 0px;
  }

  .login__wrapper {
    height: calc(100vh - 61px);
  }

  .header__buttons + .search__button {
    position: relative;
    top: -1px;
  }

  .info__grid .elem__info > .head__ > p {
    min-height: 0px;
  }

  .info__grid .elem__info .head__ {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .main__top .top__desc .head__ > .info__ > h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .main__top .top__desc .head__ > .info__ > p {
    font-size: 20px;
  }

  .main__top .top__desc .head__ {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main__top .top__desc .head__ > span {
    margin-top: 10px;
  }

  .main__top .top__media {
    min-width: 0px;
  }

  .modal__wrapper > .inner__modal > .metrics .metrics__wrapper {
    grid-template-columns: 1fr;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    > p {
    font-size: 18px;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric:last-child:after {
    content: none;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -20px;
    width: 100%;
    height: 1px;
    background: url("../img/dashvertical.svg");
    background-size: cover;
    background-position: center;
    background-repeat: repeat-x;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    > p {
    min-height: 0px;
    margin-bottom: 0px;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    .row__grid {
    margin-top: 15px;
  }
  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    .row__grid.no__space {
    margin-top: 0px;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    .row__grid
    .row__
    > span {
    font-size: 16px;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    .row__grid
    .row__
    > p {
    font-size: 18px;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    .row__grid {
    padding: 0px !important;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric:before {
    content: none !important;
  }

  .modal__wrapper > .inner__modal > .metrics .metrics__wrapper .elem__metric {
    margin-bottom: 40px;
  }
  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric:last-child {
    margin-bottom: 0px;
  }

  .modal__wrapper
    > .inner__modal
    > .metrics
    .metrics__wrapper
    .elem__metric
    .row__grid
    .row__ {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .info__grid .elem__info > .head__ > p {
    font-size: 21px;
  }

  .info__grid .elem__info .content__ > .row > span {
    font-size: 21px;
  }

  .info__grid .elem__info .content__ > .row > p {
    font-size: 24px;
  }

  .team__wrapper > p {
    font-size: 21px;
  }

  .team__wrapper .team__grid > .elem__team .team__desc > p {
    font-size: 18px;
    line-height: 23px;
  }

  .team__wrapper .team__grid > .elem__team .team__desc > span {
    font-size: 15px;
    line-height: 21px;
  }

  .team__wrapper .team__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .main__grid > p {
    font-size: 21px;
  }

  .main__grid > p {
    margin-bottom: 10px;
  }

  .main__grid .grid__wrapper {
    grid-template-columns: 1fr;
  }

  div.main__grid .grid__wrapper > .elem__grid:after {
    content: none !important;
  }
  div.main__grid .grid__wrapper > .elem__grid:last-child:before {
    content: none !important;
  }
  div.main__grid .grid__wrapper > .elem__grid:before {
    content: "" !important;
    position: absolute !important;
    left: 0px !important;
    top: auto !important;
    bottom: -1px !important;
    height: 1px !important;
    width: 100% !important;
    background: url("../img/dashvertical.svg") !important;
    background-repeat: repeat-x !important;
    background-size: 240px !important;
  }

  .main__grid .grid__wrapper > .elem__grid:after {
    content: none;
  }

  header .outer__header .header__right .header__buttons + .search__button {
    margin-left: 0px;
  }

  header .outer__header .header__right .header__buttons {
    margin-right: 15px;
  }

  header .outer__header .header__right .header__buttons a > img {
    max-width: 20px;
  }

  header .outer__header .header__right .header__buttons > div {
    margin-right: 15px;
  }

  header
    .outer__header
    .header__right
    .header__buttons
    .notifications__button
    > a
    > img {
    max-width: 20px;
  }

  .login__wrapper {
    height: calc(100vh - 56px);
  }

  header .outer__header .header__left > a {
    font-size: 19px;
  }

  header .outer__header .header__right .sign__Ini > a {
    font-size: 16px;
  }

  header .outer__header .header__right .sign__Ini > a .profile__icon img {
    min-width: 35px;
    max-width: 35px;
  }

  .modal__wrapper
    > .inner__modal
    > .data__room
    .folder__wrapper
    > .elem__folder
    > p {
    font-size: 16px;
  }

  .modal__wrapper
    > .inner__modal
    > .data__room
    .folder__wrapper
    > .add__folder
    > p {
    font-size: 16px;
  }
}
@media (max-width: 640px) {
  .main__top .top__media {
    margin-bottom: 40px;
  }

  .main__top .top__media > .mobile__ > .frame > a > img {
    max-width: 55px;
  }

  .upload > a > span {
    font-size: 9px;
    min-width: 90px;
    max-width: 110px;
    padding: 5px;
  }

  .main__top .top__media > .desktop__ > .frame > a > img {
    max-width: 55px;
  }

  .main__top .top__media .product__image > a > img {
    max-width: 55px;
  }

  .main__top .top__media .product__image {
    max-width: 180px;
  }

  .main__top .top__media > .mobile__ {
    margin-top: -120px;
  }

  .main__top .top__media .product__image {
    margin-top: -100px;
  }
  .main__top .top__media .product__image > img {
    max-width: 180px;
  }

  .main__top .top__media > .mobile__ > img {
    max-width: 150px;
  }

  .main__top .top__media > .mobile__ {
    max-width: 150px;
  }
}
@media (max-width: 540px) {
  .elem__company {
    max-width: 85%;
  }
  .modal__wrapper > .inner__modal > .data__room .folder__wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 500px) {
  header .outer__header .header__right .header__buttons {
    margin-right: 4px;
  }
  /* .header__mod_2 {
    margin-right: -26px !important;
  } */
}

@media (max-width: 480px) {
  .main__top .top__desc .head__ > .info__ > h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .main__top .top__desc .head__ > .info__ > p {
    font-size: 17px;
  }

  .main__top .top__media > .mobile__ {
    margin-right: 30px;
  }

  .modal__wrapper > .inner__modal > .data__room .folder__wrapper {
    padding-bottom: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  .team__wrapper .team__grid {
    grid-template-columns: 1fr;
  }

  .companies__wrapper > .companies__main .companies__grid > .elem__company {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .companies__wrapper
    > .companies__main
    .companies__grid
    > .elem__company
    > .company__logo {
    min-width: 130px;
    max-width: 130px;
    min-height: 130px;
    max-height: 130px;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__info
    > span
    > img {
    min-width: 50px;
    max-width: 50px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__list {
    padding-left: 0px;
    padding-right: 0px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__list
    ul
    > li
    > a
    > span {
    margin-right: 12px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__list
    ul
    > li
    > a
    .desc__
    > p {
    font-size: 15px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__list
    ul
    > li
    > a
    .desc__
    > span {
    font-size: 13px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__list
    ul
    > li
    > a
    > span
    img {
    max-height: 25px;
    max-width: 17px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__list
    ul
    > li
    > a
    > span {
    min-width: 38px;
    max-width: 38px;
    min-height: 38px;
    max-height: 38px;
  }

  header .outer__header .header__right .profile__wrapper .profile__box {
    min-width: 270px;
    max-width: 270px;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__info
    .profile__desc
    > span {
    word-break: break-all;
  }

  header
    .outer__header
    .header__right
    .profile__wrapper
    .profile__box
    .profile__info
    .profile__desc
    > p {
    font-size: 19px;
  }

  .login__wrapper .sign__box .sign__main .sign__buttons > a > img {
    right: 18px;
  }

  .login__wrapper .sign__box {
    padding: 20px 15px;
  }

  header .outer__header .header__left > a {
    font-size: 16px;
  }

  header .outer__header .header__right .sign__Ini > a {
    font-size: 15px;
  }

  header .outer__header .header__right .sign__Ini > a .profile__icon {
    margin-left: 7px;
  }

  .group__input > textarea {
    font-size: 14px;
    height: 145px;
  }

  .group__input > input {
    font-size: 14px;
    height: 35px;
  }

  .group__submit > button {
    height: 40px;
  }
}
@media (max-width: 400px) {
  .main__top .top__media .product__image {
    margin-top: -60px;
  }

  .main__top .top__media > .mobile__ > img {
    max-width: 110px;
  }

  .main__top .top__media > .mobile__ {
    max-width: 110px;
  }

  .main__top .top__media > .mobile__ {
    margin-right: 0px;
  }

  .main__top .top__media .product__image > img {
    max-width: 155px;
  }

  .main__top .top__media .product__image {
    max-width: 155px;
  }
  .main__top .top__media > .mobile__ > .frame {
    padding: 0px;
  }
}

/* ChatMainFile.css */

.chat-drop-down-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 400px;
  height: 570px;
  z-index: 99;
  right: 0;
  bottom: 0;
}

.chat-drop-down-container__MCP {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.close-button-container {
  width: 90%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.close-icon {
  width: 12px;
  height: 12px;
}

.content-container {
  width: 80%;
  height: 500px;
  background-color: white;
  box-shadow: 1px 2px 9px -1px rgba(66, 68, 90, 1);
  border-radius: 8px;
  overflow: scroll;
}

.content-container__MCP {
  width: 96%;
  /* height: auto; */
}

.str-chat__main-panel {
  padding: 0 !important;
}

::-webkit-scrollbar {
  display: none;
}

.search-results {
  position: absolute;
  z-index: 99;
  width: 80.1%;
  right: 40px;
  height: 410px;
  background-color: white;
  /* border-bottom: 1px solid black; */
  overflow-y: auto;
}

.search-results__MCP {
  position: absolute;
  z-index: 99;
  width: 313px;
  height: 417px;
  background-color: white;
  /* border-bottom: 1px solid black; */
  overflow-y: auto;
}

/* Top container */

.channel-header-container {
  display: flex;
  border-radius: 50px;
  align-content: center;
  justify-content: space-between;
  height: 30px;
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
}

/* .back-button {
} */

.channel-name-text {
  font-weight: 700;
  font-size: 13px;
  display: inline-grid;
  align-content: center;
  text-align: center;
}

/* Search bar by message or username */

.search-container {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-container {
  width: 93%;
  display: flex;
  height: 25px;
  align-items: center;
  background-color: #5b539d1f;
  border-radius: 9px;
  margin-top: 5px;
}

.inner-container__MCP {
  width: 96%;
  display: flex;
  height: 25px;
  align-items: center;
  background-color: #5b539d1f;
  border-radius: 9px;
  /* margin-top: 5px; */
}

.search-image {
  width: 12px;
  height: 12px;
  margin-right: 16px;
  margin-left: 15px;
}

.search-input {
  width: 96%;
  font-size: 13px;
  margin-right: 10px;
  height: 27px;
  background-color: #0000ff00;
}

.message-list {
  box-sizing: border-box;
  height: 430px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
}

.message-list__MCP {
  box-sizing: border-box;
  height: calc(100dvh - 214.5px);
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
}

.message-list__MCP-mobile {
  box-sizing: border-box;
  /* height: 69vh; */
  padding-left: 13px;
  padding-right: 13px;
  /* padding-top: 5px; */
  overflow: scroll;
}

.back-button-content {
  display: flex;
  margin-left: 3px;
  width: 289px;
  justify-content: space-between;
}

.back-button-icon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.back-button-text {
  font-size: 14px;
  font-weight: 700;
}

.your-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 400px;
  height: 570px;
  z-index: 99;
  top: 20%;
  left: 70%;
}

.conversations-heading {
  font-weight: 700;
  padding-left: 10px;
  padding-top: 10px;
}

.unread-messages {
  padding-left: 11px;
  font-size: 12px;
}

.search-bar {
  width: 94%;
  display: flex;
  height: 25px;
  align-items: center;
  background-color: #5b539d1f;
  border-radius: 9px;
  margin: 10px;
}

.search-icon {
  width: 12px;
  height: 12px;
  margin-right: 16px;
  margin-left: 15px;
}

.channel-list {
  list-style-type: none;
  padding: 0;
}

.channel-item {
  width: 100%;
  display: flex;
  height: 40px;
  cursor: pointer;
  border-top: 1px solid #95959570;
  background-color: white;
}

.channel-avatar {
  width: 50px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.avatar-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.channel-details {
  font-size: 12px;
  margin-top: 11px;
}

.channel-name {
  height: 15px;
}

.unread-badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5b539d1f;
  position: relative;
  left: -22px;
  font-weight: 600;
  bottom: 18px;
}

.last-message {
  font-size: 10px;
  margin-top: 11px;
}

.search__bar-for-mobile {
  display: none;
}

.navbat__formattedName {
  font-size: 22px;
  font-weight: 600;
  margin-right: 10px;
  margin-top: 6px;
}

.navbar__enterButton {
  width: 22px;
  height: 22px;
  position: relative;
  right: 20px;
  top: 2px;
}

.navbar__unreadCount-container {
  bottom: 32px;
}

@media (max-width: 990px) {
  .container__navbar {
    height: 120px;
  }
  .navbar__unreadCount-container {
    bottom: 28px;
  }
  .search__bar-for-mobile {
    display: flex;
    width: 76%;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 50px;
    height: 40px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .search__bar-for-mobile {
    width: 98%;
  }
}

@media (max-width: 500px) {
  .navbat__formattedName {
    width: 65px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    text-align: end;
    margin-top: 6px;
  }
  .navbar__userImage {
    width: 30px !important;
    height: 30px !important;
  }
  .search__bar-for-mobile {
    display: flex;
    width: 99%;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 50px;
    height: 35px;
    align-items: center;
    justify-content: center;
  }
  .navbar__enterButton {
    width: 20px;
    height: 20px;
  }
}

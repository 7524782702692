.ToogleMenu__container {
  width: 300px;
}

@media (max-width: 500px) {
  .ToogleMenu__container {
    width: 100%;
    right: 0;
    left: 0;
  }
}
